import { client } from 'js/src/client';
import { ApiError, isUndefined } from 'js/src/libs/consts';
import { ErrorReporter } from 'js/src/libs/error-reporter';
import Cookies from 'js-cookie';


function getCSRFToken(): string {
    const csrfToken = Cookies.get('csrftoken');
    return isUndefined(csrfToken) ? '' : csrfToken;
}

function getWorkspaceId(): string {
    return isUndefined(SERVER?.USER?.workspace_id) ? '' : `${SERVER.USER.workspace_id}`;
}

/*
 * Compress a string using the CompressionStream API
 */
async function compressWithCompressionStream(input: string): Promise<Blob> {
    const encoder = new TextEncoder();
    const inputStream = new ReadableStream({
        start(controller) {
            controller.enqueue(encoder.encode(input));
            controller.close();
        },
    });

    const compressedStream = inputStream.pipeThrough(new CompressionStream('gzip'));
    const response = new Response(compressedStream);
    return response.blob();
}

/*
 * Compress large request bodies (saving 90-95% bandwidth)
 */
export async function gzipBigRequests(request: Request, thresholdBytes = 300 * 1024): Promise<Request> {
    try {
        const method = request.method.toUpperCase();

        if (['POST', 'PUT', 'PATCH'].includes(method)) {
            const originalBody = await request.clone().text();
            const encoded = new TextEncoder().encode(originalBody);

            if (encoded.length > thresholdBytes) {
                const compressedBlob = await compressWithCompressionStream(originalBody);

                const newHeaders = new Headers(request.headers);
                newHeaders.set('Content-Encoding', 'gzip');

                return new Request(request.url, {
                    method,
                    headers: newHeaders,
                    body: compressedBlob,
                    credentials: request.credentials,
                    cache: request.cache,
                    redirect: request.redirect,
                    referrer: request.referrer,
                    referrerPolicy: request.referrerPolicy,
                    integrity: request.integrity,
                    keepalive: request.keepalive,
                    signal: request.signal,
                    mode: request.mode,
                });
            }
        }

        return request;
    }
    catch (error) {
        ErrorReporter.capture(error);
        return request;
    }
}

client.interceptors.request.use(async(request) => {
    request.headers.set('Content-Type', 'application/json');

    // add the csrf token to the headers in all requests, so that django's
    // csrf protection will not reject requests sent via the OpenAPI client
    request.headers.set('X-CSRFToken', getCSRFToken());

    // add the Lumen5 workspace id to the headers in all requests, so that
    // the backend can determine which workspace the request is for
    request.headers.set('X-LUMEN5-WORKSPACE', getWorkspaceId());

    // for large requests, we gzip the body to reduce the size of the request
    return gzipBigRequests(request);
});

client.interceptors.error.use((error, response, request, opts) => {
    if (!(error instanceof Error)) {
        return new ApiError(response.status, error, opts.url);
    }
    return error;
});

client.setConfig({
    throwOnError: true,
});
