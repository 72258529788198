/* eslint-disable react/no-unused-prop-types */
import 'css/src/components/common/ui/Button';

import type { ComponentProps, ForwardedRef } from 'react';
import React from 'react';
import classNames from 'classnames';

import Spinner from 'js/src/components/common/ui/Spinner';
import type { Placement } from 'js/src/components/common/ui/Tooltip';
import Tooltip from 'js/src/components/common/ui/Tooltip';


export enum BUTTON_SIZES {
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}

export enum BUTTON_VARIANTS {
    FILLED = 'filled',
    OUTLINE = 'outline',
    LINK = 'link',
    MEDIA = 'media',
    TEXT = 'text'
}

export enum BUTTON_SHAPES {
    ROUNDED = 'rounded',
    CIRCLE = 'circle',
    SQUARE = 'square',
    RECTANGLE = 'rectangle'
}

export enum BUTTON_COLORS {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    DARK = 'dark',
    DANGER = 'danger',
    SIMPLE = 'simple',
    UPSELL = 'upsell',
}

export enum BUTTON_TOOLTIP_PLACEMENTS {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
}

export const SPINNER_ICON = 'spinner-icon';

export enum ICON_VARIANTS {
    FILLED = 'filled',
    OUTLINE = 'outline',
}

export enum BUTTON_TYPE {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset'
}

export enum BUTTON_WIDTHS {
    NORMAL = 'normal', // enforces a min-width
    NO_MIN_WIDTH = 'no-min-width',
}

export enum BUTTON_PADDING {
    NONE = 'no-padding',
}

/**
 * Our button component!
 *
 * Typical types of buttons we'd want:
 *
 *
 * - A "Primary" button:
 * ```
 * <Button text="click me"/>
 * ```
 *
 *
 * - A "Secondary" button:
 * ```
 * <Button text="click me" variant={BUTTON_VARIANTS.OUTLINE} />
 * ```
 *
 *
 * - A "Default" button:
 * ```
 * <Button text="click me" color={BUTTON_COLORS.TERTIARY} />
 * ```
 *
 *
 * - An "Icon" button:
 * ```
 * <Button text="" iconLeft="delete" />
 * ```
 */
export type ButtonType = {
    className?: string, // The className string to append, d'uh!
    textClassName?: string, // The className to apply to the text span!
    text?: React.ReactNode, // the text to put on the button. Leave empty for an icon button.
    size?: BUTTON_SIZES, // how large the button should be.
    variant?: BUTTON_VARIANTS, // the style variant of the button.
    color?: BUTTON_COLORS, // the fill color of the button.
    shape?: BUTTON_SHAPES, // the shape button.
    disabled?: boolean, // whether or not the button is currently in the disabled state.
    iconLeft?: string | React.ReactNode | null, // the icon name to put on the left of the text. Either material icon or custom React icon component. Alternatively,
                              // use SPINNER_ICON to show a spinner in the left side of the button.
    iconLeftVariant?: ICON_VARIANTS, // the type of material icon to use (filled vs outline) on the left
    iconRight?: string | React.ReactNode | null, // the icon name to put on the left of the text. Either material icon or custom React icon component. Alternatively,
                               // use SPINNER_ICON to show a spinner on the right side of the button.
    iconRightVariant?: ICON_VARIANTS, // the type of material icon to use (filled vs outline) on the right
    tooltipTitle?: string, // an additional tooltip to add onto the button when the user hovers.
    tooltipPlacement?: BUTTON_TOOLTIP_PLACEMENTS, // where the tooltip should be placed.
    tooltipContainer?: string, // CSS selector of where the tooltip should be appended to.
    href?: string, // if this is passed in, an <a/> tag will be rendered with the href attribute
    hrefTarget?: string, // the "target" attribute, if rendering an <a/> tag (eg. set it to "_blank"
                         // to open the href in a new window)
    onClick?: (event: React.MouseEvent<HTMLButtonElement|HTMLAnchorElement>) => void, // a callback for when a non-disabled button is clicked.
    onMouseDown?: (event: React.MouseEvent<HTMLButtonElement|HTMLAnchorElement>) => void,
    type?: BUTTON_TYPE,
    width?: BUTTON_WIDTHS,
    padding?: BUTTON_PADDING,
    'data-testid'?: string,
    'data-bs-toggle'?: string,
    'data-bs-target'?: string,
    showAlertBubble?: boolean,
    useIconButtonPresets?: boolean,
    id?: string,
    isIconPill?: boolean,
    isActive?: boolean,
}
function ButtonFunc({
    className,
    textClassName,
    text,
    size = BUTTON_SIZES.MD,
    variant = BUTTON_VARIANTS.FILLED,
    color = BUTTON_COLORS.PRIMARY,
    shape = BUTTON_SHAPES.ROUNDED,
    disabled = false,
    iconLeft,
    iconLeftVariant = ICON_VARIANTS.FILLED,
    iconRight,
    iconRightVariant = ICON_VARIANTS.FILLED,
    tooltipTitle,
    tooltipPlacement = BUTTON_TOOLTIP_PLACEMENTS.TOP,
    tooltipContainer,
    href,
    hrefTarget,
    onClick,
    onMouseDown,
    type = BUTTON_TYPE.BUTTON,
    width = BUTTON_WIDTHS.NORMAL,
    padding,
    'data-testid': dataTestId,
    'data-bs-toggle': dataToggle,
    'data-bs-target': dataTarget,
    showAlertBubble = false,
    useIconButtonPresets,
    id,
    isIconPill,
    isActive = false,
}: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    let iconLeftNode = null;
    if (iconLeft === SPINNER_ICON) {
        iconLeftNode = (
            <Spinner size='sm' className={classNames({ 'me-l5-4': text })} />
        );
    }
    else if (typeof iconLeft === 'string') {
        // Material Icon
        iconLeftNode = (
            <i className={classNames('material-symbols-outlined', {
                'me-l5-4': text,
                'material-symbols-outlined': iconLeftVariant === ICON_VARIANTS.OUTLINE,
            })}>{iconLeft}</i>
        );
    }
    else if (React.isValidElement(iconLeft)) {
        // Custom Icon
        iconLeftNode = iconLeft;
    }

    let iconRightNode = null;
    if (iconRight === SPINNER_ICON) {
        iconRightNode = (
            <Spinner size='sm' className={classNames({ 'ms-l5-4': text })} />
        );
    }
    else if (typeof iconRight === 'string') {
        // Material Icon
        iconRightNode = (
            <i className={classNames('material-symbols-outlined', {
                'ms-l5-4': text,
                'material-symbols-outlined': iconRightVariant === ICON_VARIANTS.OUTLINE,
            })}>
                {iconRight}
            </i>
        );
    }
    else if (React.isValidElement(iconRight)) {
        // Custom icon
        iconRightNode = iconRight;
    }

    const onClickCallback = React.useCallback((e: React.MouseEvent<HTMLButtonElement|HTMLAnchorElement>) => {
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (onClick) {
            onClick(e);
        }
    }, [disabled, onClick]);

    const onMouseDownCallback = React.useCallback((e: React.MouseEvent<HTMLButtonElement|HTMLAnchorElement>) => {
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (onMouseDown) {
            onMouseDown(e);
        }
    }, [disabled, onMouseDown]);

    const props: ComponentProps<'button'|'a'> = {
        id,
        disabled,
        type,
        onClick: onClickCallback,
        onMouseDown: onMouseDownCallback,
        className: classNames(
            className,
            /*
            We are temporarily using the lumen5-button class to avoid
            conflicts with buttons in the current codebase.
            Once all the buttons use this component, we can delete this class.
            */
            'lumen5-button',
            'btn', {
                // Shapes
                'btn-rounded': shape === BUTTON_SHAPES.ROUNDED,
                'btn-square': shape === BUTTON_SHAPES.SQUARE,
                'btn-circle': shape === BUTTON_SHAPES.CIRCLE,
                'btn-rectangle': shape === BUTTON_SHAPES.RECTANGLE,

                // Colors
                'btn-primary': color === BUTTON_COLORS.PRIMARY,
                'btn-tertiary': color === BUTTON_COLORS.TERTIARY,
                'btn-secondary': color === BUTTON_COLORS.SECONDARY,
                'btn-dark': color === BUTTON_COLORS.DARK,
                'btn-danger': color === BUTTON_COLORS.DANGER,
                'btn-simple': color === BUTTON_COLORS.SIMPLE,
                'btn-upsell': color === BUTTON_COLORS.UPSELL,

                // Variants
                'btn-outline': variant === BUTTON_VARIANTS.OUTLINE,
                'btn-link': variant === BUTTON_VARIANTS.LINK,
                'btn-media': variant === BUTTON_VARIANTS.MEDIA,
                'btn-text': variant === BUTTON_VARIANTS.TEXT,

                // Sizes
                'btn-sm': size === BUTTON_SIZES.SM,
                'btn-md': size === BUTTON_SIZES.MD,
                'btn-lg': size === BUTTON_SIZES.LG,
                'btn-xl': size === BUTTON_SIZES.XL,

                // Icon button
                'btn-icon p-l5-0': useIconButtonPresets,

                // Widths
                'btn-no-min-width': width === BUTTON_WIDTHS.NO_MIN_WIDTH,

                // Padding
                'btn-no-padding': padding === BUTTON_PADDING.NONE,

                // Alert bubble
                'alert-bubble': showAlertBubble,

                // Pill icon button
                'btn-pill': isIconPill,

                // State
                'active': isActive,
            }
        ),
        // @ts-expect-error does not exist in type
        'data-testid': dataTestId,
        'data-bs-toggle': dataToggle,
        'data-bs-target': dataTarget,
    };

    const children = (
        <>
            {iconLeftNode}
            <span className={textClassName} >{text}</span>
            {iconRightNode}
        </>
    );

    let btnNode: React.ReactNode;
    if (href) { // if href is passed in, render as a link instead of a button
        btnNode = (
            <a
                {...props as ComponentProps<'a'>}
                href={href}
                target={hrefTarget}
                ref={ref as React.RefObject<HTMLAnchorElement>}
            >
                {children}
            </a>
        );
    }
    else {
        btnNode = (
            <button
                {...props as ComponentProps<'button'>}
                ref={ref as React.RefObject<HTMLButtonElement>}
            >
                {children}
            </button>
        );
    }

    if (tooltipTitle) {
        return (
            <Tooltip
                title={tooltipTitle}
                placement={tooltipPlacement as unknown as Placement}
                container={tooltipContainer}
            >
                {/* bootstrap will add attributes to the element that the tooltip text needs to be
                    appended to. However, for components that have a disabled state, these attributes
                    don't seem to show if the item is in the disabled state. A workaround is to wrap
                    a container so the tooltip attributes will be modified on the <div/>, and the
                    tooltip will show even if the component item is disabled.
                    This is recommended by the bootstrap documentation:
                    https://getbootstrap.com/docs/4.0/components/tooltips/#disabled-elements
                     */}
                <div className='tooltip-wrapper'>
                    {btnNode}
                </div>
            </Tooltip>
        );
    }

    return btnNode;
}
const Button = React.memo(React.forwardRef(ButtonFunc));

Button.displayName = 'Button';

export default Button;

/**
 * @type {any}
 */
export const PrimaryButton = React.memo(React.forwardRef(function PrimaryButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button {...args} ref={ref} />
    );
}));
PrimaryButton.displayName = 'PrimaryButton';

/**
 * @type {any}
 */
export const SecondaryButton = React.memo(React.forwardRef(function SecondaryButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.OUTLINE}
            color={BUTTON_COLORS.PRIMARY}
            {...args}
            ref={ref}
        />
    );
}));
SecondaryButton.displayName = 'SecondaryButton';

export const TertiaryButton = React.memo(React.forwardRef(function TertiaryButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.OUTLINE}
            color={BUTTON_COLORS.TERTIARY}
            {...args}
            ref={ref}
        />
    );
}));
TertiaryButton.displayName = 'TertiaryButton';

export const SimpleButton = React.memo(React.forwardRef(function SimpleButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            color={BUTTON_COLORS.SIMPLE}
            {...args}
            ref={ref}
        />
    );
}));
SimpleButton.displayName = 'SimpleButton';

export const DangerButton = React.memo(React.forwardRef(function DangerButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            color={BUTTON_COLORS.DANGER}
            {...args}
            ref={ref}
        />
    );
}));
DangerButton.displayName = 'DangerButton';

export const DangerSecondaryButton = React.memo(React.forwardRef(function DangerSecondaryButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            color={BUTTON_COLORS.DANGER}
            variant={BUTTON_VARIANTS.OUTLINE}
            {...args}
            ref={ref}
        />
    );
}));
DangerSecondaryButton.displayName = 'DangerSecondaryButton';

export const PrimaryTextButton = React.memo(React.forwardRef(function PrimaryTextButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.TEXT}
            color={BUTTON_COLORS.PRIMARY}
            padding={BUTTON_PADDING.NONE}
            {...args}
            ref={ref}
        />
    );
}));
PrimaryTextButton.displayName = 'PrimaryTextButton';

export const SecondaryTextButton = React.memo(React.forwardRef(function SecondaryTextButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.TEXT}
            color={BUTTON_COLORS.SECONDARY}
            {...args}
            ref={ref}
        />
    );
}));
SecondaryTextButton.displayName = 'SecondaryTextButton';

export const TertiaryTextButton = React.memo(React.forwardRef(function TertiaryTextButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.TEXT}
            color={BUTTON_COLORS.TERTIARY}
            {...args}
            ref={ref}
        />
    );
}));
TertiaryTextButton.displayName = 'TertiaryTextButton';

export const DangerTextButton = React.memo(React.forwardRef(function DangerTextButton({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.TEXT}
            color={BUTTON_COLORS.DANGER}
            {...args}
            ref={ref}
        />
    );
}));
DangerTextButton.displayName = 'DangerTextButton';

// Allow for supplying a single icon parameter for a more intuitive use of IconButtons
// Either a string for a material icon or a React node for a custom icon
type IconButtonType = ButtonType & {
    icon: string | React.ReactNode;
};

export const IconButton = React.memo(React.forwardRef(function IconButton({ ...args }: IconButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            iconLeft={args.icon}
            useIconButtonPresets={true}
            {...args}
            text=''
            ref={ref}
        />
    );
}));
IconButton.displayName = 'IconButton';

export const PrimaryIconButton = React.memo(React.forwardRef(function PrimaryIconButton({ ...args }: IconButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.LINK}
            color={BUTTON_COLORS.PRIMARY}
            iconLeft={args.icon}
            useIconButtonPresets={true}
            {...args}
            ref={ref}
        />
    );
}));
PrimaryIconButton.displayName = 'PrimaryIconButton';

export const SecondaryIconButton = React.memo(React.forwardRef(function SecondaryconButton({ ...args }: IconButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.LINK}
            color={BUTTON_COLORS.TERTIARY}
            iconLeft={args.icon}
            useIconButtonPresets={true}
            {...args}
            ref={ref}
        />
    );
}));
SecondaryIconButton.displayName = 'SecondaryIconButton';

export const TertiaryIconButton = React.memo(React.forwardRef(function TertiaryIconButton({ ...args }: IconButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.LINK}
            color={BUTTON_COLORS.DARK}
            iconLeft={args.icon}
            useIconButtonPresets={true}
            {...args}
            ref={ref}
        />
    );
}));
TertiaryIconButton.displayName = 'TertiaryIconButton';

export const PrimaryIconPillButton = React.memo(React.forwardRef(function PrimaryIconPillButton({ ...args }: IconButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            color={BUTTON_COLORS.PRIMARY}
            iconLeft={args.icon}
            useIconButtonPresets={true}
            isIconPill={true}
            {...args}
            ref={ref}
        />
    );
}));
PrimaryIconPillButton.displayName = 'PrimaryIconPillButton';

export const SecondaryIconPillButton = React.memo(React.forwardRef(function SecondaryIconPillButton({ ...args }: IconButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.OUTLINE}
            color={BUTTON_COLORS.PRIMARY}
            iconLeft={args.icon}
            useIconButtonPresets={true}
            isIconPill={true}
            {...args}
            ref={ref}
        />
    );
}));
SecondaryIconPillButton.displayName = 'SecondaryIconPillButton';

export const TertiaryIconPillButton = React.memo(React.forwardRef(function TertiaryIconPillButton({ ...args }: IconButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.OUTLINE}
            color={BUTTON_COLORS.TERTIARY}
            iconLeft={args.icon}
            useIconButtonPresets={true}
            isIconPill={true}
            {...args}
            ref={ref}
        />
    );
}));
TertiaryIconPillButton.displayName = 'TertiaryIconPillButton';

export const MediaIconButton = React.memo(React.forwardRef(function MediaIconButton({ ...args }: IconButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            shape={BUTTON_SHAPES.SQUARE}
            variant={BUTTON_VARIANTS.MEDIA}
            color={BUTTON_COLORS.PRIMARY}
            iconLeft={args.icon}
            size={BUTTON_SIZES.MD}
            useIconButtonPresets={true}
            {...args}
            ref={ref}
        />
    );
}));
MediaIconButton.displayName = 'MediaIconButton';

export const DangerMediaIconButton = React.memo(React.forwardRef(function DangerMediaIconButton({ ...args }: IconButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            className="danger"
            shape={BUTTON_SHAPES.SQUARE}
            variant={BUTTON_VARIANTS.MEDIA}
            color={BUTTON_COLORS.PRIMARY}
            iconLeft={args.icon}
            size={BUTTON_SIZES.MD}
            useIconButtonPresets={true}
            {...args}
            ref={ref}
        />
    );
}));
DangerMediaIconButton.displayName = 'DangerMediaIconButton';

export const InlineLink = React.memo(React.forwardRef(function InlineLink({ ...args }: ButtonType, ref: ForwardedRef<HTMLButtonElement|HTMLAnchorElement>) {
    return (
        <Button
            variant={BUTTON_VARIANTS.LINK}
            padding={BUTTON_PADDING.NONE}
            {...args}
            ref={ref}
        />
    );
}));
InlineLink.displayName = 'InlineLink';
